import React from 'react';
import logo from './logoTransparentSquare.png';
import { GoogleAuthProvider, User as FirebaseUser } from 'firebase/auth';
import { Authenticator, FirebaseCMSApp } from 'firecms';
import { EmailAuthProvider } from 'firebase/auth';
import { productionFirebaseCredentials, stagingFirebaseCredentials } from './firebaseCredentials';
import { Firestore, FirestoreSettings, doc, getDoc, getFirestore, initializeFirestore, setDoc, Timestamp } from 'firebase/firestore';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import scholarshipsCollection from './collections/scholarshipsCollection';
import videosCollection from './collections/videosCollection';
import resourceCollection from './collections/resourceCollection';
import eventsCollection from './collections/eventsCollection';
import adviceQuestionsCollection from './collections/adviceQuestionsCollection';
import questionsCollection from './collections/questionsCollection';
import longFormsCollection from './collections/longFormsCollection';
import quotesCollection from './collections/quotesCollection';
import linksCollection from './collections/linksCollection';
import partnersCollection from './collections/partnersCollection';
import markdownCollection from './collections/markdownCollection';
import cmsUserCollection from './collections/cmsUsersCollection';
import platformUserCollection from './collections/platformUsersCollection';
import ContentWall from './ContentWall';
import Publish from './Publish';
import offerSubmissionsCollection from './collections/offerSubmissionsCollection';
import Actions from './Actions';

let firebaseConfig = {};
export const isProductionEnv = process.env.NODE_ENV === 'production' && ['cms.joinaccountingplus.com', 'caq-cms.web.app', 'caq-cms-test.web.app'].includes(window.location.hostname);
export let currFirestore: Firestore;
export let firebaseApp: FirebaseApp;
export let firebaseCredentials: {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
};

if (isProductionEnv) {
  const fireStoreSettings: FirestoreSettings = {
    ignoreUndefinedProperties: true,
  };
  firebaseCredentials = productionFirebaseCredentials;
  firebaseApp = initializeApp(productionFirebaseCredentials);
  currFirestore = initializeFirestore(firebaseApp, fireStoreSettings);
  firebaseConfig = {
    ...productionFirebaseCredentials,
  };
} else {
  firebaseCredentials = stagingFirebaseCredentials;
  firebaseApp = initializeApp(stagingFirebaseCredentials);
  currFirestore = getFirestore(firebaseApp);
  firebaseConfig = {
    ...stagingFirebaseCredentials,
  };
}

export const fbStorage = getStorage();

export default function App() {
  enum eUserRoles {
    admin = 'admin',
    associate = 'associate',
    applicant = 'applicant',
  }

  interface CmsUser {
    docId?: string;
    role: eUserRoles;
    displayName: string;
    email: string;
    dateCreated?: Timestamp;
    dateUpdated?: Timestamp;
  }

  const myAuthenticator: Authenticator<FirebaseUser> = async ({ user, authController }) => {
    if (!user) {
      return false;
    }

    let role = 'applicant';

    const docSnap = await getDoc(doc(currFirestore, 'users', user.uid));

    if (docSnap.exists()) {
      const userData = docSnap.data() as CmsUser;
      role = userData.role as eUserRoles;

      if (![eUserRoles.admin, eUserRoles.associate].includes(role as eUserRoles)) {
        return false;
      } else {
        return onUserAcquired();
      }
    } else {
      const newUserData: CmsUser = {
        docId: user.uid,
        role: eUserRoles.applicant,
        displayName: user.displayName || '',
        email: user.email || '',
        dateCreated: Timestamp.fromMillis(Date.now()),
        dateUpdated: Timestamp.fromMillis(Date.now()),
      };
      await setDoc(doc(currFirestore, 'users', user.uid), newUserData).catch((err) => {
        console.error(err);
      });

      return onUserAcquired();
    }

    async function onUserAcquired() {
      // This is an example of retrieving async data related to the user
      // and storing it in the user extra field.      
      const sampleUserData = await Promise.resolve({
        roles: [role],
      });

      authController.setExtra(sampleUserData);
      if (![eUserRoles.admin, eUserRoles.associate].includes(role as eUserRoles)) {
        return false;
      } else {
        return true;
      }
    }
  };

  const DEFAULT_SIGN_IN_OPTIONS = [EmailAuthProvider.PROVIDER_ID, GoogleAuthProvider.PROVIDER_ID];

  const additionalViews = [
    {
      group: 'Publish',
      path: '/contentWall',
      name: 'Content Wall',
      view: <ContentWall collectionName="ContentWall" />,
    },
    {
      group: 'Publish',
      path: '/careerResourcesContentWall',
      name: 'Career Resources Content Wall',
      view: <ContentWall collectionName="CareerResourcesContentWall" />,
    },
    {
      group: 'Publish',
      path: '/publish',
      name: 'Trigger Rebuild',
      view: <Publish />,
    },
    {
      group: 'Actions',
      path: '/actions',
      name: 'Actions',
      view: <Actions/>,
    },    
  ];

  return (
    <div>
      <FirebaseCMSApp
        name={'accounting+ CMS'}
        logo={logo}
        signInOptions={DEFAULT_SIGN_IN_OPTIONS}
        authentication={myAuthenticator}
        collections={[
          resourceCollection,
          eventsCollection,
          adviceQuestionsCollection,
          questionsCollection,
          videosCollection,
          longFormsCollection,
          quotesCollection,
          linksCollection,
          markdownCollection,
          offerSubmissionsCollection,          
          cmsUserCollection,          
          platformUserCollection,
          partnersCollection,
          scholarshipsCollection,
        ]}
        firebaseConfig={firebaseConfig}
        views={additionalViews}
      />
    </div>
  );
}
